/*
 * YORB 2020
 *
 * Aidan Nelson, April 2020
 *
 */

//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// IMPORTS
//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
import 'regenerator-runtime/runtime';

import { Yorb } from './yorb';
import { SimpleMediasoupPeer } from './libs/smp';
import { projectList } from './projectListWinterShow2021';

const io = require('socket.io-client');

import debugModule from 'debug';

const log = debugModule('YORB');
const warn = debugModule('YORB:WARN');
const err = debugModule('YORB:ERROR');
const info = debugModule('YORB:INFO');

// load p5 for self view
const p5 = require('p5');

let WEB_SOCKET_SERVER = false;
let INSTANCE_PATH = false;

// For running against local server
WEB_SOCKET_SERVER = 'localhost:3000';
INSTANCE_PATH = '/socket.io';

// For running against ITP server
WEB_SOCKET_SERVER = 'https://yorb.itp.io';
INSTANCE_PATH = '/socket.io';

//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// Setup Global Variables:
//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

// TODO: https://www.mattburkedev.com/export-a-global-to-the-window-object-with-browserify/

//
// export all the references we use internally to manage call state,
// to make it easy to tinker from the js console. for example:
//
//   `Client.camVideoProducer.paused`
//
export let mySocketID,
    socket,
    simpleMediasoupPeer,
    localCam,
    localScreen,
    webcamVideoPaused = false,
    webcamAudioPaused = false,
    yorbScene,
    projects = [],
    miniMapSketch,
    selfViewSketch,
    initialized = false;

window.clients = {}; // array of connected clients for three.js scene
window.lastPollSyncData = {};

//for tutorial checks
let isInTutorial = true;

// adding constraints, VIDEO_CONSTRAINTS is video quality levels
// localMediaCOnstraints is passed to the getUserMedia object to request a lower video quality than the maximum
// I believe some webcam settings may override this request

const VIDEO_CONSTRAINTS = {
    qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
    vga: { width: { ideal: 640 }, height: { ideal: 480 } },
    hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
};
let localMediaConstraints = {
    audio: true,
    video: {
        width: VIDEO_CONSTRAINTS.qvga.width,
        height: VIDEO_CONSTRAINTS.qvga.height,
        frameRate: { max: 30 },
    },
};

//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// Start-Up Sequence:
//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

// start with user interaction with the DOM so we can auto-play audio/video from
// now on...
window.onload = async () => {
    info('Window loaded.');

    createScene();
    updateProjects(projectList);
    createMiniMap();

    var startButton = document.getElementById('enterButton');
    startButton.addEventListener('click', init);
};

async function init() {
    document.getElementById('overlay').style.visibility = 'hidden';

    // only join room after we user has interacted with DOM (to ensure that media elements play)
    if (!initialized) {
        await initSocketConnection();
        setTimeout(() => {
            sendCameraStreams();
        }, 2000);
        setupControls();
        turnGravityOn();
        hitPlay();
        initialized = true;
        isInTutorial = false;
        setInterval(selectivelyConnectToPeers, 3000);
    }
    yorbScene.camera.layers.set(0);
}

export async function launchTutorial() {
    document.getElementById('overlay').style.visibility = 'hidden';

    turnGravityOn();
    isInTutorial = true;

    // yorbScene.camera.layers.set(1);
    yorbScene.camera.layers.enable(2);
    yorbScene.startTutorial();
}

export async function leaveTutorial() {
    isInTutorial = false;
    yorbScene.tutorial = undefined;

    init();
}

function selectivelyConnectToPeers() {
    let closestPeers = yorbScene.getClosestPeers(8);
    // ensure we have all of these peers connected
    for (const peerId of closestPeers) {
        simpleMediasoupPeer.connectToPeer(peerId);
    }

    // then pause all other peers:
    for (const peerId in clients) {
        if (closestPeers.includes(peerId)) {
            simpleMediasoupPeer.resumePeer(peerId);
        } else {
            simpleMediasoupPeer.pausePeer(peerId);
        }
    }
}

//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// Socket.io
//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

// establishes socket connection
// uses promise to ensure that we receive our so
function initSocketConnection() {
    return new Promise((resolve) => {
        info('Initializing socket.io...');

        socket = io(WEB_SOCKET_SERVER, {
            path: INSTANCE_PATH,
        });

        simpleMediasoupPeer = new SimpleMediasoupPeer(socket);
        simpleMediasoupPeer.onTrack = (track, id, label) => {
            // console.log(`Got track of kind ${label} from ${id}`);
            let el = document.getElementById(id + '_' + label);
            if (track.kind === 'video') {
                if (el == null) {
                    // console.log('Creating video element for client with ID: ' + id);
                    el = document.createElement('video');
                    el.id = id + '_' + label;
                    el.autoplay = true;
                    el.muted = true;
                    // el.style = 'visibility: hidden;';
                    document.body.appendChild(el);
                    el.setAttribute('playsinline', true);
                    document.body.appendChild(el);
                }

                // TODO only update tracks if the track is different
                // console.log('Updating video source for client with ID: ' + id);
                el.srcObject = null;
                el.srcObject = new MediaStream([track]);

                el.onloadedmetadata = (e) => {
                    el.play().catch((e) => {
                        console.log('Play video error: ' + e);
                    });
                };
            }
            if (track.kind === 'audio') {
                if (el == null) {
                    // console.log('Creating audio element for client with ID: ' + id);
                    el = document.createElement('audio');
                    el.id = id + '_' + label;
                    document.body.appendChild(el);
                    el.setAttribute('playsinline', true);
                    el.setAttribute('autoplay', true);
                }

                // console.log('Updating <audio> source object for client with ID: ' + id);
                el.srcObject = null;
                el.srcObject = new MediaStream([track]);
                el.volume = 0;

                el.onloadedmetadata = (e) => {
                    el.play().catch((e) => {
                        console.log('Play video error: ' + e);
                    });
                };
            }
        };

        //socket.on('connect', () => {});

        //On connection server sends the client his ID and a list of all keys
        socket.on('introduction', (_id, _ids) => {
            // keep a local copy of my ID:
            info('My socket ID is: ' + _id);
            mySocketID = _id;
            yorbScene.mySocketID = _id;
            resolve();

            // for each existing user, add them as a client and add tracks to their peer connection
            for (let i = 0; i < _ids.length; i++) {
                if (_ids[i] != mySocketID) {
                    addClient(_ids[i]);
                }
            }

            // when a new user has entered the server
            socket.on('newUserConnected', (clientCount, _id, _ids) => {
                info(clientCount + ' clients connected');

                if (!(_id in clients)) {
                    if (_id != mySocketID) {
                        info('A new user connected with the id: ' + _id);
                        addClient(_id);
                    }
                }
            });

            // socket.on('projects', (_projects) => {
            //     info('Received project list from server.');
            //     updateProjects(_projects);
            // });

            socket.on('userDisconnected', (_id, _ids) => {
                // Update the data from the server

                if (_id in clients) {
                    if (_id == mySocketID) {
                        info('Uh oh!  The server thinks we disconnected!');
                    } else {
                        info('A user disconnected with the id: ' + _id);
                        yorbScene.removeClient(_id);
                        removeClientDOMElements(_id);
                        delete clients[_id];
                    }
                }
            });

            // Update when one of the users moves in space
            socket.on('userPositions', (_clientProps) => {
                yorbScene.updateClientPositions(_clientProps);
            });

            socket.on('projectionScreenUpdate', (_clientProps) => {
                yorbScene.updateProjectionScreenOwnership(_clientProps);
            });

            // listen for projection screen changes:
            socket.on('releaseProjectionScreen', (data) => {
                info('Releasing screen with id', data.screenId);
                yorbScene.releaseProjectionScreen(data.screenId);
            });
        });
    });
}

//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// Clients / WebRTC
//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

// Adds client object with THREE.js object, DOM video object and and an RTC peer connection for each :
async function addClient(_id) {
    info('Adding client with id ' + _id);
    clients[_id] = {};
    yorbScene.addClient(_id);
}

function updateProjects(_projects) {
    projects = _projects;
    if (yorbScene.updateProjects) {
        yorbScene.updateProjects(projects);
        yorbScene.createHtmlProjectList(projects);
    }
}

function hitPlay() {
    // check and see if we've visited #buds ...
    // if(window.location.hash == '#buds') {
    // yorbScene.budsGallery.addDisplays()
    // }
}

//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// Three.js 🌻
//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

function onPlayerMove() {
    if (!isInTutorial) {
        socket.emit('move', yorbScene.getPlayerPosition());
    }
}

export function hackToRemovePlayerTemporarily() {
    info('removing user temporarily');
    let pos = [0, 10000, 0];
    let rotation = [0, 0, 0];
    socket.emit('move', [pos, rotation]);

    for (let _id in clients) {
        // pauseAllConsumersForPeer(_id);
    }
}

function createScene() {
    // initialize three.js scene
    info('Creating three.js scene...');

    yorbScene = new Yorb(onPlayerMove, clients, mySocketID);
    yorbScene.updateProjects(projects);
}

//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// User Interface 🚂
//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

// notes for myself (and anyone else...)
// the webcam can be in a few different states:
// 	- we have not yet requested user media
// 	- we have requested user media but have been denied
// 	- we do have user media

// the send transport can be in a few different states:
// 	- we have not yet set it up
// 	- we have set it up and are currently sending camera and microphone feeds
// 	- we have set it up, but are not sending camera or microphone feeds (i.e. we are paused)

let pos = [];
function setupControls() {
    window.addEventListener(
        'keyup',
        (e) => {
            if (e.keyCode == 67) {
                // "C"
                toggleWebcamVideoPauseState();
            }
            if (e.keyCode == 77) {
                // "M"
                toggleWebcamAudioPauseState();
            }
            // if (e.keyCode == 13) { // "Enter"
            // 	yorbScene.activateHighlightedProject();
            // }
            if (e.keyCode == 49) {
                // "1"
                yorbScene.swapMaterials();
            }
            if (e.keyCode == 80) {
                // 'p'
                let position = yorbScene.getPlayerPosition()[0];
                console.log(position);
                let url = `https://yorb.itp.io/?x=${position[0].toFixed(2)}&y=${position[1].toFixed(2)}&z=${position[2].toFixed(2)}`;
                console.log('Have your friends meet you here: ', url);
                makePositionLinkModal(position);
            }
            if (e.keyCode == 85) {
                // 'p'

                let position = yorbScene.getPlayerPosition()[0];
                // console.log(position);
                pos.push(position);
                console.log(pos);
                // let url = `https://yorb.itp.io/?x=${position[0].toFixed(2)}&y=${position[1].toFixed(2)}&z=${position[2].toFixed(2)}`;
                // console.log('Have your friends meet you here: ', url);
                // makePositionLinkModal(position);
            }
        },
        false
    );
}

function makePositionLinkModal(position) {
    // parse project descriptions to render without &amp; etc.
    // https://stackoverflow.com/questions/3700326/decode-amp-back-to-in-javascript

    if (!document.getElementsByClassName('project-modal')[0]) {
        yorbScene.controls.pause();
        let modalEl = document.createElement('div');
        modalEl.className = 'project-modal';
        modalEl.id = 'link_modal';

        let contentEl = document.createElement('div');
        contentEl.className = 'project-modal-content';

        let link = `https://yorb.itp.io/?x=${position[0].toFixed(2)}&y=${position[1].toFixed(2)}&z=${position[2].toFixed(2)}`;

        let linkEl = document.createElement('a');
        linkEl.href = link;
        linkEl.innerHTML = 'Have your friends meet you with this link!';
        linkEl.target = '_blank';
        linkEl.rel = 'noopener noreferrer';

        let closeButton = document.createElement('button');
        closeButton.addEventListener('click', () => {
            modalEl.remove();
            yorbScene.controls.resume();
        });
        closeButton.innerHTML = 'X';

        let spacerDiv = document.createElement('div');
        spacerDiv.innerHTML += '<br><br>';

        let spacerDiv2 = document.createElement('div');
        spacerDiv2.innerHTML += '<br><br>';

        contentEl.appendChild(closeButton);
        contentEl.appendChild(spacerDiv);
        contentEl.appendChild(linkEl);
        contentEl.appendChild(spacerDiv2);

        modalEl.appendChild(contentEl);
        document.body.appendChild(modalEl);
    }
}

function turnGravityOn() {
    yorbScene.controls.turnGravityOn();
}

function toggleWebcamImage() {
    let webcamImage = document.getElementById('webcam-status-image');
    if (getCamPausedState()) {
        webcamImage.src = require('../assets/images/no-webcam.png');
    } else {
        webcamImage.src = require('../assets/images/webcam.png');
    }
}

function toggleMicrophoneImage() {
    let micImg = document.getElementById('microphone-status-image');
    if (getMicPausedState()) {
        micImg.src = require('../assets/images/no-mic.png');
    } else {
        micImg.src = require('../assets/images/mic.png');
    }
}

// adapted (with ❤️) from Dan Shiffman: https://www.youtube.com/watch?v=rNqaw8LT2ZU
async function createSelfView() {
    const s = (sketch) => {
        let video;
        var vScale = 8;
        let ballX = 100;
        let ballY = 100;
        let velocityX = sketch.random(-5, 5);
        let velocityY = sketch.random(-5, 5);
        let buffer = 10;

        sketch.setup = () => {
            // console.log(sketch);
            let canvas = sketch.createCanvas(260, 200);
            ballX = sketch.width / 2;
            ballY = sketch.height / 2;
            sketch.pixelDensity(1);
            // video = sketch.addElement(localCam, sketch, true);
            // const videoTrack = localCam.getVideoTracks()[0];
            // video = sketch.createVideo();
            let domElement = document.getElementById('localVideo');
            video = new p5.MediaElement(domElement, sketch);
            sketch._elements.push(video);
            // console.log(sketch._elements);

            domElement.addEventListener('loadedmetadata', function () {
                domElement.play();
                if (domElement.width) {
                    video.width = domElement.width;
                    video.height = domElement.height;
                } else {
                    video.width = video.elt.width = domElement.videoWidth;
                    video.height = video.elt.height = domElement.videoHeight;
                }
                video.loadedmetadata = true;
            });
            // console.log(video);

            // console.log(video);
            // video.elt.width = 240;
            // video.elt.height = 120;
            // video.elt.srcObject = new MediaStream([videoTrack]);
            // video.elt.onloadedmetadata = (e) => {
            //     video.elt.play().catch((e) => {
            //         console.log('Play video error: ' + e);
            //     });
            // };
            // video = sketch.createCapture(sketch.VIDEO);
            video.size(sketch.width / vScale, sketch.height / vScale);
            // video.hide();
            sketch.frameRate(2);
            sketch.rectMode(sketch.CENTER);
            sketch.ellipseMode(sketch.CENTER);
        };

        sketch.draw = () => {
            if (webcamVideoPaused) {
                // bouncing ball easter egg sketch:
                sketch.background(10, 10, 200);
                ballX += velocityX;
                ballY += velocityY;
                if (ballX >= sketch.width - buffer || ballX <= buffer) {
                    velocityX = -velocityX;
                }
                if (ballY >= sketch.height - buffer || ballY <= buffer) {
                    velocityY = -velocityY;
                }
                sketch.fill(240, 120, 0);
                sketch.ellipse(ballX, ballY, 10, 10);
            } else {
                // console.log('draw');
                sketch.background(0);
                // sketch.image(video,0,0,sketch.width,sketch.height);
                // sketch.filter(sketch.THRESHOLD);
                video.loadPixels();
                for (var y = 0; y < video.height; y++) {
                    for (var x = 0; x < video.width; x++) {
                        var index = (video.width - x + 1 + y * video.width) * 4;
                        var r = video.pixels[index + 0];
                        var g = video.pixels[index + 1];
                        var b = video.pixels[index + 2];
                        var bright = (r + g + b) / 3;
                        var w = sketch.map(bright, 0, 255, 0, vScale);
                        sketch.noStroke();
                        sketch.fill(255);
                        sketch.rectMode(sketch.CENTER);
                        sketch.rect(x * vScale, y * vScale, w, w);
                    }
                }
            }
        };
    };
    selfViewSketch = new p5(s, document.getElementById('self-view-canvas-container'));
    selfViewSketch.canvas.style = 'display: block; margin: 0 auto;';
}

// creates minimap p5 sketch
async function createMiniMap() {
    const s = (sketch) => {
        let mapImg = false;

        sketch.setup = () => {
            mapImg = sketch.loadImage(require('../assets/images/map.png'));
            sketch.createCanvas(300, 300);
            sketch.pixelDensity(1);
            sketch.frameRate(5);
            sketch.ellipseMode(sketch.CENTER);
            sketch.imageMode(sketch.CENTER);
            sketch.angleMode(sketch.RADIANS);
        };

        sketch.draw = () => {
            sketch.background(0);
            sketch.push();

            // translate to center of sketch
            sketch.translate(sketch.width / 2, sketch.height / 2);
            //translate to 0,0 position of map and make all translations from there
            let playerPosition = yorbScene.getPlayerPosition();
            let posX = playerPosition[0][0];
            let posZ = playerPosition[0][2];

            // TODO add in direction...
            // let myDir = playerPosition[1][1]; // camera rotation about Y in Euler Radians

            // always draw player at center:
            sketch.push();
            sketch.fill(255, 255, 0);
            sketch.ellipse(0, 0, 7, 7);
            // TODO add in direction...
            // sketch.fill(0, 0, 255,150);
            // sketch.rotate(myDir);
            // sketch.triangle(0, 0, -10, -30, 10, -30);
            sketch.pop();

            let mappedX = sketch.map(posZ, 0, 32, 0, -225, false);
            let mappedY = sketch.map(posX, 0, 32, 0, 225, false);
            // allow for map load time without using preload, which seems to mess with things in p5 instance mode...
            sketch.push();
            sketch.rotate(Math.PI);
            sketch.translate(mappedX, mappedY);
            // if (mapImg) {
            //     sketch.image(mapImg, 0, 0, mapImg.width, mapImg.height);
            // }
            for (let id in clients) {
                let pos = clients[id].group.position; // [x,y,z] array of position
                let yPos = sketch.map(pos.x, 0, 32, 0, -225, false);
                let xPos = sketch.map(pos.z, 0, 32, 0, 225, false);
                sketch.push();
                sketch.fill(100, 100, 255);
                sketch.translate(xPos, yPos);
                sketch.ellipse(0, 0, 5, 5);
                sketch.pop();
            }
            sketch.pop();
            sketch.pop();
        };
    };
    miniMapSketch = new p5(s, document.getElementById('mini-map-canvas-container'));
    miniMapSketch.canvas.style = 'display: block; margin: 0 auto;';
}

// remove <video> element and corresponding <canvas> using client ID
function removeClientDOMElements(_id) {
    info('Removing DOM elements for client with ID: ' + _id);

    let videoEl = document.getElementById(_id + '_video');
    if (videoEl != null) {
        videoEl.remove();
    }
    let canvasEl = document.getElementById(_id + '_canvas');
    if (canvasEl != null) {
        canvasEl.remove();
    }
    let audioEl = document.getElementById(_id + '_audio');
    if (audioEl != null) {
        audioEl.remove();
    }
}

//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// Mediasoup Code:
//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

export async function sendCameraStreams() {
    log('send camera streams');

    let devicesInfo = await navigator.mediaDevices.enumerateDevices();
    gotDevices(devicesInfo);
    await startStream();
    createSelfView();
}

export async function shareScreen(screenId) {
    log('start screen share');

    try {
        // get a screen share track
        localScreen = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: {
                autoGainControl: false, // seems to make it mono if true
                echoCancellation: false,
                noiseSupression: false,
            },
        });

        // also make a local video Element to hold the stream
        let videoEl = document.getElementById(mySocketID + '_screenshare');
        if (!videoEl) {
            videoEl = document.createElement('video');
            videoEl.setAttribute('id', mySocketID + '_screenshare');
            videoEl.setAttribute('muted', true);
            videoEl.setAttribute('autoplay', true);
            videoEl.setAttribute('style', 'visibility: hidden;');
            document.body.appendChild(videoEl);
        }

        let videoTrack = localScreen.getVideoTracks()[0];
        let videoStream = new MediaStream([videoTrack]);
        videoEl.srcObject = videoStream;
        // videoEl.srcObject = localScreen

        // make an audio element to hold the stream (and have the volume updated positionally)
        // Positional Audio Works in Firefox:
        // Global Audio:
        let audioEl = document.getElementById(mySocketID + '_screenshareAudio');
        if (audioEl == null) {
            audioEl = document.createElement('audio');
            audioEl.id = `${mySocketID}_screenshareAudio`;
            audioEl.setAttribute('playsinline', true);
            audioEl.setAttribute('autoplay', true);
            document.body.appendChild(audioEl);
        }

        info('Adding local screenshare <audio> source object');
        let audioTrack = localScreen.getAudioTracks()[0];
        if (audioTrack) {
            let audioStream = new MediaStream([audioTrack]);
            audioEl.srcObject = audioStream;
            audioEl.volume = 0; // start at 0 and let the three.js scene take over from here...
        }

        // let's "yield" and return before playing, rather than awaiting on
        // play() succeeding. play() will not succeed on a producer-paused
        // track until the producer unpauses.
        audioEl
            .play()
            .then(() => {})
            .catch((e) => {
                info('Play audio error: ' + e);
                err(e);
            });

        // create a producer for video
        if (videoTrack) {
            simpleMediasoupPeer.addTrack(videoTrack, 'screenshare', true);
        }
        if (audioTrack) {
            simpleMediasoupPeer.addTrack(audioTrack, 'screenshareAudio', true);
        }

        // handler for screen share stopped event (triggered by the
        // browser's built-in screen sharing ui)
        videoTrack.addEventListener('ended', async () => {
            log('screen share stopped');
            try {
                info('releasing', screenId);
                socket.emit('releaseProjectionScreen', {
                    screenId: screenId,
                });
            } catch (e) {
                console.error(e);
            }
        });

        // then tell the server we claim that screen:
        socket.emit('claimProjectionScreen', {
            screenId: screenId,
        });
    } catch (e) {
        console.error(e);
    }
}


export async function shareScreenLocalVideo(screenId) {
    log('start screen share');

    try {
        // get a screen share track
        localScreen = localCam.clone();
        // also make a local video Element to hold the stream
        let videoEl = document.getElementById(mySocketID + '_screenshare');
        if (!videoEl) {
            videoEl = document.createElement('video');
            videoEl.setAttribute('id', mySocketID + '_screenshare');
            videoEl.setAttribute('muted', true);
            videoEl.setAttribute('autoplay', true);
            videoEl.setAttribute('style', 'visibility: hidden;');
            document.body.appendChild(videoEl);
        }

        let videoTrack = localScreen.getVideoTracks()[0];
        let videoStream = new MediaStream([videoTrack]);
        videoEl.srcObject = videoStream;
        // videoEl.srcObject = localScreen

        // make an audio element to hold the stream (and have the volume updated positionally)
        // Positional Audio Works in Firefox:
        // Global Audio:
        // let audioEl = document.getElementById(mySocketID + '_screenshareAudio');
        // if (audioEl == null) {
        //     audioEl = document.createElement('audio');
        //     audioEl.id = `${mySocketID}_screenshareAudio`;
        //     audioEl.setAttribute('playsinline', true);
        //     audioEl.setAttribute('autoplay', true);
        //     document.body.appendChild(audioEl);
        // }

        // info('Adding local screenshare <audio> source object');
        let audioTrack = localScreen.getAudioTracks()[0];
        // if (audioTrack) {
        //     let audioStream = new MediaStream([audioTrack]);
        //     audioEl.srcObject = audioStream;
        //     audioEl.volume = 0; // start at 0 and let the three.js scene take over from here...
        // }

        // let's "yield" and return before playing, rather than awaiting on
        // play() succeeding. play() will not succeed on a producer-paused
        // track until the producer unpauses.
        // audioEl
        //     .play()
        //     .then(() => {})
        //     .catch((e) => {
        //         info('Play audio error: ' + e);
        //         err(e);
        //     });

        // create a producer for video
        if (videoTrack) {
            simpleMediasoupPeer.addTrack(videoTrack, 'screenshare', true);
        }
        if (audioTrack) {
            simpleMediasoupPeer.addTrack(audioTrack, 'screenshareAudio', true);
        }

        // handler for screen share stopped event (triggered by the
        // browser's built-in screen sharing ui)
        videoTrack.addEventListener('ended', async () => {
            log('screen share stopped');
            try {
                info('releasing', screenId);
                socket.emit('releaseProjectionScreen', {
                    screenId: screenId,
                });
            } catch (e) {
                console.error(e);
            }
        });

        // then tell the server we claim that screen:
        socket.emit('claimProjectionScreen', {
            screenId: screenId,
        });
    } catch (e) {
        console.error(e);
    }
}

// export async function startCamera() {
//     if (localCam) {
//         return;
//     }
//     log('start camera');
//     try {
//         localCam = await navigator.mediaDevices.getUserMedia(localMediaConstraints);
//         // await navigator.mediaDevices.enumerateDevices().then(gotDevices).catch((err) => {
//         //     console.error(err);
//         // });
//         createSelfView();
//     } catch (e) {
//         console.error('Start camera error', e);
//         webcamAudioPaused = true;
//         webcamVideoPaused = true;
//         toggleWebcamImage();
//         toggleMicrophoneImage();
//     }
// }

const videoElement = document.getElementById('localVideo');
const audioInputSelect = document.querySelector('select#audioSource');
const audioOutputSelect = document.querySelector('select#audioOutput');
const videoInputSelect = document.querySelector('select#videoSource');
const selectors = [audioInputSelect, audioOutputSelect, videoInputSelect];

audioOutputSelect.disabled = !('sinkId' in HTMLMediaElement.prototype);

audioInputSelect.addEventListener('change', startStream);
videoInputSelect.addEventListener('change', startStream);
audioOutputSelect.addEventListener('change', changeAudioDestination);

function gotDevices(deviceInfos) {
    // Handles being called several times to update labels. Preserve values.
    const values = selectors.map((select) => select.value);
    selectors.forEach((select) => {
        while (select.firstChild) {
            select.removeChild(select.firstChild);
        }
    });
    for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        const option = document.createElement('option');
        option.value = deviceInfo.deviceId;
        if (deviceInfo.kind === 'audioinput') {
            option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
            audioInputSelect.appendChild(option);
        } else if (deviceInfo.kind === 'audiooutput') {
            option.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
            audioOutputSelect.appendChild(option);
        } else if (deviceInfo.kind === 'videoinput') {
            option.text = deviceInfo.label || `camera ${videoInputSelect.length + 1}`;
            videoInputSelect.appendChild(option);
        } else {
            console.log('Some other kind of source/device: ', deviceInfo);
        }
    }
    selectors.forEach((select, selectorIndex) => {
        if (Array.prototype.slice.call(select.childNodes).some((n) => n.value === values[selectorIndex])) {
            select.value = values[selectorIndex];
        }
    });
}

function gotStream(stream) {
    localCam = stream; // make stream available to console

    if ('srcObject' in videoElement) {
        videoElement.srcObject = stream;
    } else {
        videoElement.src = window.URL.createObjectURL(stream);
    }

    // videoElement.onloadedmetadata = (e) => {
    //     videoElement.play().catch((e) => {
    //         console.log('Play video error: ' + e);
    //     });
    // };

    const videoTrack = localCam.getVideoTracks()[0];
    const audioTrack = localCam.getAudioTracks()[0];
    simpleMediasoupPeer.addTrack(videoTrack, 'video');
    simpleMediasoupPeer.addTrack(audioTrack, 'audio');

    // Refresh button list in case labels have become available
    return navigator.mediaDevices.enumerateDevices();
}

function handleError(error) {
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
}

// Attach audio output device to video element using device/sink ID.
function attachSinkId(element, sinkId) {
    if (typeof element.sinkId !== 'undefined') {
        element
            .setSinkId(sinkId)
            .then(() => {
                console.log(`Success, audio output device attached: ${sinkId}`);
            })
            .catch((error) => {
                let errorMessage = error;
                if (error.name === 'SecurityError') {
                    errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                }
                console.error(errorMessage);
                // Jump back to first output device in the list as it's the default.
                audioOutputSelect.selectedIndex = 0;
            });
    } else {
        console.warn('Browser does not support output device selection.');
    }
}

function changeAudioDestination() {
    const audioDestination = audioOutputSelect.value;
    attachSinkId(videoElement, audioDestination);
}

async function startStream() {
    if (localCam) {
        localCam.getTracks().forEach((track) => {
            track.stop();
        });
    }

    const audioSource = audioInputSelect.value;
    const videoSource = videoInputSelect.value;
    const constraints = {
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
        video: { deviceId: videoSource ? { exact: videoSource } : undefined, width: { ideal: 320 }, height: { ideal: 240 } },
    };
    navigator.mediaDevices.getUserMedia(constraints).then(gotStream).then(gotDevices).catch(handleError);
}

export function getCamPausedState() {
    return webcamVideoPaused;
}

export function getMicPausedState() {
    return webcamAudioPaused;
}

export function getScreenPausedState() {
    return screenShareVideoPaused;
}

export function getScreenAudioPausedState() {
    return screenShareAudioPaused;
}

export async function toggleWebcamVideoPauseState() {
    if (!localCam) return;
    if (getCamPausedState()) {
        // resumeProducer(camVideoProducer);
        localCam.getVideoTracks()[0].enabled = true;
    } else {
        // pauseProducer(camVideoProducer);
        localCam.getVideoTracks()[0].enabled = false;
    }
    webcamVideoPaused = !webcamVideoPaused;
    toggleWebcamImage();
}

export async function toggleWebcamAudioPauseState() {
    if (!localCam) return;
    if (getMicPausedState()) {
        // resumeProducer(camAudioProducer);
        localCam.getAudioTracks()[0].enabled = true;
    } else {
        // pauseProducer(camAudioProducer);
        localCam.getAudioTracks()[0].enabled = false;
    }
    webcamAudioPaused = !webcamAudioPaused;
    toggleMicrophoneImage();
}
